.pulse-green {
	transform: scale(1);
	animation: pulse-green 3s infinite;
}

.pulse-green:hover {
	animation: none;
}

.pendingER {
    transform: scale(1);
    animation: pulse-red 3s infinite;
}

.pendingER:hover {
    box-shadow: 5px;
}

@keyframes pulse-red {
    0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-green {
    0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}